import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { IntCountProvider } from './src/utils/IntCountContext';
import { InvestProvider } from './src/utils/InvestContext';

export const wrapRootElement = ({ element }) => (
  <IntCountProvider>
    <InvestProvider>
      {element}
      <Analytics />
    </InvestProvider>
  </IntCountProvider>
);